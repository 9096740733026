/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('#homepage_video_modal').on('shown.bs.modal', function (e) {
    // start youtube video when open the modal window
    $('.embed-container iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
  });
  $('#homepage_video_modal').on('hide.bs.modal', function (e) {
    // stop youtube video when close the modal window
    $('.embed-container iframe')[0].contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
  });

  $(window).scroll(function() {
    /* affix after scrolling 100px */
    if ($(document).scrollTop() >= 1) {
      // >= 992 (xl up)
      $('header.banner').addClass('scrolling');
    } else {
      $('header.banner').removeClass('scrolling');
    }
  });

  $(".featured__item").click(function() {
    window.location = $(this).find("a").attr("href");
    return false;
  });

  // // When the user scrolls the page, execute myFunction
  // window.onscroll = function() {myFunction()};
  //
  // // Get the navbar
  // var navbar = document.getElementById("navbar");
  //
  // // Get the offset position of the navbar
  // var sticky = navbar.offsetTop;
  //
  // // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
  // function myFunction() {
  //   if (window.pageYOffset >= sticky) {
  //     navbar.classList.add("sticky")
  //   } else {
  //     navbar.classList.remove("sticky");
  //   }
  // }

  // Tooltips for ingredients
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  // handle filters on resource library
  $('#filter_region').change(function() {
    $qs = '';
    if ($("input[name=keyword]").val() !== '') {
      $qs = "&keyword=" + $("input[name=keyword]").val();
    }
    window.location = '/resource-library/?region=' + $(this).val() + $qs;
  });

  $('#filter_country').change(function() {
    $qs = '';
    if ($("input[name=keyword]").val() !== '') {
      $qs = "&keyword=" + $("input[name=keyword]").val();
    }
    window.location = '/resource-library/?country=' + $(this).val() + $qs;
  });

  $('.collapse').on('shown.bs.collapse', function(){
    $(this).parent().find(".fa-plus").removeClass("fa-plus").addClass("fa-minus");
  }).on('hidden.bs.collapse', function(){
    $(this).parent().find(".fa-minus").removeClass("fa-minus").addClass("fa-plus");
  });

  // If search is populated show clear filters X icon
  if( $('.resource-search-input-wrapper .search-field').val() ) {  //if it is populated
    $('.resource-search-input-wrapper .clear-resource-search').show();
  }

  $('.resource-search-input-wrapper .clear-resource-search').click(function() {
    // $('.resource-search-input-wrapper .clear-resource-search').hide();
    window.location.href='/resource-library/';
  });

  // modify activity (events manager) info windows to disable them
  // jQuery(document).bind('em_maps_location_hook', function( e, map, infowindow, marker ) {
  //   var infowindow = new google.maps.InfoWindow({
  //     content: "";
  //   });
  // });

  jQuery(document).bind('em_maps_location_hook', function( e, map, infowindow, marker ){
  	setTimeout(function(){	infowindow.close(); var latlng = new google.maps.LatLng(map.center.lat(),map.center.lng()); marker.setPosition(latlng); }, 500);
  });

})(jQuery); // Fully reference jQuery after this point.
